<template>
  <div class="d-flex flex-column flex-root">
    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        :class="{
        'login-signin-on': this.state === 'signin'
      }"
        id="eta"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto">
        <div class="d-flex flex-column-fluid flex-center flex-right">
          <img
              src="media/logos/Gecko.png" height="350" width="300"
              class="max-w-400px"
              alt=""
          />
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="eta_signin_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <span class="title font-weight-bolder">
                  iTex+<br>IVTool
                </span>
              </div>
              <div class="form-group">
                <label class="font-size-h6">Benutzername</label>
                <div
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                >
                  <input
                      class="form-control form-control-solid h-auto py-7 px-6"
                      type="text"
                      name="email"
                      ref="email"
                      v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 pt-5">Passwort</label>
                </div>
                <div
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                >
                  <input
                      class="form-control form-control-solid h-auto py-7 px-6"
                      type="password"
                      name="password"
                      ref="password"
                      v-model="form.password"
                      autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                    ref="eta_signin_submit"
                    class="btn font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                    style="background-color: #53a318; border-color: #53a318; color: white; width: 100%"
                >
                  LOGIN
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
        <!--begin::Content footer-->
        <div
            class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <div>
            IVTool V1.0
          </div>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
.title {
  font-size: 3rem !important;
}
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGOUT, LOGIN } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        //user_name: "seyas",
        //password: "qwertz"
        //email: "",
        //password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
          process.env.BASE_URL + "media/svg/illustrations/login_visual_ship.png"
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("eta_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        user_name: {
          validators: {
            notEmpty: {
              message: "Benutzernamen eingeben"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Passwort eingeben"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["eta_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      new Promise(resolve => {
        ApiService.post("auth/login", { email, password })
            .then(data => {
              if (data.data) {
                this.removeSpinner(submitButton);
                this.$store.dispatch(LOGIN, data.data);
                this.$router.push({ name: "dashboard" });
                resolve(data.data);
              }
            })
            .catch(({ response }) => {
              this.removeSpinner(submitButton);
              Swal.fire({
                title: "Warning " + response.status,
                text: "Bitte überprüfen Sie Ihre Zugangsdaten",
                icon: "warning",
                heightAuto: false
              });
            });
      });
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Bitte überprüfen Sie Ihre Zugangsdaten",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  },
  methods: {
    removeSpinner(submitButton) {
      submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
      );
    },
    showForm(form) {
      this.state = form;
      var form_name = "eta_" + form + "_form";
      KTUtil.animateClass(
          KTUtil.getById(form_name),
          "animate__animated animate__backInUp"
      );
    }
  }
};
</script>
